/*!

=========================================================
* BLK Design System PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create charts
import { Line } from "react-chartjs-2";
import Roadmap from '../RoadMap';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  ListGroupItem,
  ListGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import DemoFooter from "components/Footers/DemoFooter.js";

import { chartExample1 } from "variables/charts.js";

export default function LandingPage() {
  const wrapper = React.useRef(null);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    wrapper.current.scrollTop = 0;
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  }, []);
  return (
    <>
      <ColorNavbar />
      <div className="wrapper" ref={wrapper}>
        <div className="page-header">
          {/* <img
            alt="..."
            className="path"
            src={require("assets/img/blob.png").default}
          /> */}
          <img
            alt="..."
            className="path2"
            src={require("assets/img/path2.png").default}
          />
          {/* <img
            alt="..."
            className="shapes triangle"
            src={require("assets/img/triunghiuri.png").default}
          />
          <img
            alt="..."
            className="shapes wave"
            src={require("assets/img/waves.png").default}
          />
          <img
            alt="..."
            className="shapes squares"
            src={require("assets/img/patrat.png").default}
          />
          <img
            alt="..."
            className="shapes circle"
            src={require("assets/img/cercuri.png").default}
          /> */}
          <Container>
            <Row className="row-grid justify-content-between align-items-center text-left">
              <Col lg="6" md="6">
                <h1 class="text-head text-flame">
                  GAS•Swap <br />
                </h1>
                <p className="mb-3 text-sub text-earth">
                  Speculate and trade Ethereum gas price volatility
                </p>
                <div className="btn-wrapper mb-3">
                  <Button
                    block
                    className="btn-simple btn-round btn-flame"
                    color="white"
                    size="lg"
                    href="https://app.gasswap.io"
                    target="_blank"
                  >
                    <img style={{ width: '30px', marginRight: '20px' }} src={require("assets/png/element_logo.png").default} />
                          GO TO TESTNET
                      </Button>
                </div>
                <div className="btn-wrapper">
                  <div className="button-container">
                    <Button
                      className="btn-icon btn-flame btn-round btn-simple"
                      color="default"
                      href="https://twitter.com/gas_swap_io"
                      id="tooltip39661217"
                      target="_blank"
                    >
                      <i className="fab fa-twitter" />
                    </Button>
                    <Button
                      className="btn-icon btn-flame btn-round btn-simple ml-1"
                      color="default"
                      href="https://t.me/joinchat/zWLfXVygtjE2Y2Rl"
                      id="tooltip206037619"
                      target="_blank"
                    >
                      <i className="fab fa-telegram" />
                    </Button>
                    <Button
                      className="btn-icon btn-flame btn-round btn-simple ml-1"
                      color="default"
                      href="https://discord.gg/evRvnWcrS9"
                      id="tooltip206037619"
                      target="_blank"
                    >
                      <i className="fab fa-discord" />
                    </Button>

                    <Button
                      className="btn-icon btn-flame btn-round btn-simple ml-1"
                      color="default"
                      href="https://gasswap.medium.com"
                      id="tooltip206037619"
                      target="_blank"
                    >
                      <i className="fab fa-medium" />
                    </Button>

                  </div>
                </div>
              </Col>
              <Col lg="4" md="5">
                <img
                  alt="..."
                  className="img-fluid"
                  src={require("assets/png/mark_logo.png").default}
                />
              </Col>
            </Row>
          </Container>
        </div>

        <section className="section section-lg">
          <section className="section">
            <img
              alt="..."
              className="path"
              src={require("assets/img/path4.png").default}
            />
            <Container>
              <Row className="row-grid justify-content-between">
                <Col className="mt-lg-5" md="5">

                  <div className="px-md-5">

                    <hr className="line-flame" />
                    <h3 class="text-head text-flame">What About EIP1559?</h3>
                    <p class="text-sub text-earth">
                      Will it effect the GasSwap Protocol? No it won't, but you can read our article about it on Medium if you like...
                  </p>

                  </div>
                </Col>

                <Col className="mt-lg-5" md="5">

                  <div className="px-md-5">
                    <hr className="line-flame" />
                    <a
                      href="https://gasswap.medium.com/how-will-eip-1559-effect-gasswap-protocol-5ceb5212d868"
                      target="_blank">
                      <div style={{ cursor: 'pointer' }}>
                        {/* <img
                        alt="..."
                        className="img-fluid"
                        src={require("assets/png/mark_logo.png").default}
                      /> */}
                        <i className="fab fa-medium text-flame" style={{ fontSize: '180px' }} />
                        <a><div class="text-sub text-flame">How Will EIP 1559 Effect GasSwap Protocol?</div></a>
                        <h4 className="text-earth text-sub" style={{ fontSize: '20px' }}><i className="fa fa-calendar-alt" />{'  ' + 'May 8, 2021'}</h4>

                      </div>

                    </a>
                  </div>
                </Col>
              </Row>

            </Container>
          </section>
        </section>

        <section >
          <section className="section">
            <Container>
              <Row>
                <div className="px-md-5">

                  <hr className="line-flame" />
                  <h3 class="text-head text-flame">Features!</h3>
                  {/* <p class="text-sub text-earth">
                    GasSwap is currently running on the Rinkeby TestNet.<br></br>
                    Switch your wallet over to Rinkeby and head to UniSwap to get yourself some GSWP.<br></br>
                    Then head over the TestNet page to start staking your GSWP and learning how to use Gas Swap
                  </p> */}

                </div>
              </Row>
              {/* <img
                alt="..."
                className="path"
                src={require("assets/img/path4.png").default}
              /> */}
              {/* <img
            alt="..."
            className="path3"
            src={require("assets/img/path4.png").default}
          />
          <img
            alt="..."
            className="shapes circle"
            src={require("assets/img/cercuri.png").default}
          /> */}

              <Row>
                <Col md="4">
                  <div className="info info-hover">
                    <div className="icon icon-flame text-flame">
                      <img
                        alt="..."
                        className="bg-blob"
                        src={require("assets/img/feature-blob/warning.png").default}
                      />
                      <i className="fa fa-gas-pump" />
                    </div>
                    <h2 className="info-title text-sub text-flame">Speculate & Trade Gas Price Volatility</h2>
                    <p className="description text-hl  text-earth">
                      Stake and claim to stack your GSWP.
                    </p>
                  </div>
                </Col>
                <Col md="4">
                  <div className="info info-hover">
                    <div className="icon icon-flame text-flame">
                      <img
                        alt="..."
                        className="bg-blob"
                        src={require("assets/img/feature-blob/warning.png").default}
                      />
                      <i className="fa fa-chart-line" />
                    </div>
                    <h2 className="info-title text-sub text-flame">Off-Set High Gas Prices</h2>
                    <p className="description text-hl text-earth">
                      By staking and claiming your GSWP at the right time, you can take profits during times of high gas fees, off setting high gas prices.
                    </p>
                  </div>
                </Col>
                <Col md="4">
                  <div className="info info-hover">
                    <div className="icon icon-flame text-flame">
                      <img
                        alt="..."
                        className="bg-blob"
                        src={require("assets/img/feature-blob/warning.png").default}
                      />
                      <i className="fa fa-rocket" />
                    </div>
                    <h2 className="info-title text-sub text-flame">ICO Coming Soon</h2>
                    <p className="description text-hl  text-earth">
                      Get in early to maximise your profits from GSWP.
              </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </section>
        <section className="section">
          <section>
            <img
              alt="..."
              className="path"
              src={require("assets/img/path4.png").default}
            />
            <Container>
              <Row className="row-grid justify-content-between">
                <Col className="mt-lg-5" md="5">

                  <div className="px-md-5">

                    <hr className="line-flame" />
                    <h3 class="text-head text-flame">How Does It Work?</h3>
                    <p class="text-sub text-earth">
                      GasSwap rewards users in the form of GSWP tokens for correctly predicting price rises in Ethereum gas.
                  </p>

                  </div>
                </Col>

                <Col className="mt-lg-5" md="5">

                  <div className="px-md-5">
                    <hr className="line-flame" />

                    <ul className="list-unstyled mt-5">
                      <li>
                        <div className="d-flex align-items-center">
                          <div className="icon icon-warm mb-2">
                            <h1 class="text-head text-warm">1</h1>
                          </div>
                          <div className="ml-3">
                            <div class="text-hl text-earth">Purchase GSWP tokens from UniSwap</div>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div className="icon icon-warm mb-2">
                            <h1 class="text-head text-warm">2</h1>
                          </div>
                          <div className="ml-3">
                            <div class="text-hl text-earth">When Eth gas price is low, stake your GSWP tokens for GSWPSTK tokens.</div>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div className="icon icon-warm">
                            <h1 class="text-head text-warm">3</h1>
                          </div>
                          <div className="ml-3">
                            <div class="text-hl text-earth">When gas price is high, claim GSWP tokens for your GSWPSTK tokens.</div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>

            </Container>
          </section>
        </section>

        <Roadmap />

        <section className="section">
          <section className="section">
            {/* <img
              alt="..."
              className="path"
              src={require("assets/img/path4.png").default}
            /> */}
            <Container>
              <Row className="row-grid justify-content-between">
                <Col>

                  <div className="px-md-5">

                    <hr className="line-flame" />
                    <h3 class="text-head text-flame">TestNet Live Now!</h3>
                    <p class="text-sub text-earth">
                      GasSwap is currently running on the Rinkeby TestNet.<br></br>
                      Switch your wallet over to Rinkeby and head to UniSwap to get yourself some GSWP.<br></br>
                      Then head over the TestNet page to start staking your GSWP and learning how to use Gas Swap
                  </p>

                  </div>

                  <Row style={{ marginTop: '20px' }}>
                    <Col md="5">
                      <Button
                        block
                        className="btn-simple btn-round btn-flame"
                        color="white"
                        size="lg"
                        href="https://gasswap.s3.amazonaws.com/Gas+Swap+White+Paper+V1.pdf"
                        target="_blank"
                      >
                        <i style={{ width: '30px', marginRight: '20px', fontSize: '20px', margin: '4px' }} className="fa fa-file-alt" />
                        {/* <img style={{ width: '30px', marginRight: '20px' }} src={require("assets/uni_flame.png").default} /> */}
                      WHITE PAPER
                    </Button>

                      {/* <Button
                        block
                        className="btn-simple btn-round btn-flame"
                        color="white"
                        size="lg"
                        href="https://app.uniswap.org/#/swap?outputCurrency=0xdc561Dc05437de48e14DF522f0a76744131169Fd"
                        target="_blank"
                      >
                        <img style={{ width: '30px', marginRight: '20px' }} src={require("assets/uni_flame.png").default} />
                      GSWP ON UNISWAP
                    </Button> */}

                    </Col>
                    <Col md="5">

                      <Button
                        block
                        className="btn-simple btn-round btn-flame"
                        color="white"
                        size="lg"
                        href="https://app.gasswap.io"
                        target="_blank"
                      >
                        <img style={{ width: '30px', marginRight: '20px' }} src={require("assets/png/element_logo.png").default} />
                          GO TO TESTNET
                      </Button>

                    </Col>
                  </Row>
                </Col>
              </Row>

            </Container>
          </section>
        </section>


        <DemoFooter />
      </div>
    </>
  );
}
