import React from 'react';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    ListGroupItem,
    ListGroup,
    Container,
    Row,
    Col,
} from "reactstrap";

export default class Roadmap extends React.Component {
    render() {
        return (

            <section style={{ background: '#fab03c' }}>
                <section className="section">
                    <Container>
                        <Row>
                            <div className="px-md-5">

                                <hr className="line-white" style={{ background: 'white' }} />
                                <h3 class="text-head">Road Map!</h3>
                            </div>

                        </Row>

                        <Row>
                            <Col md="3">
                                <div className="info info-hover">
                                    <div className="icon icon-white text-white">
                                        <i className="fa fa-book" />
                                    </div>
                                    <h2 className="info-title text-sub text-white">White Paper</h2>
                                    <p className="description text-hl  text-white">
                                        February 2021
                                    </p>
                                </div>
                            </Col>
                            <Col md="3">
                                <div className="info info-hover">
                                    <div className="icon icon-white text-white">
                                        <i className="fa fa-flask" />
                                    </div>
                                    <h2 className="info-title text-sub text-white">Test Net</h2>
                                    <p className="description text-hl  text-white">
                                        March 2021
                                    </p>
                                </div>
                            </Col>
                            <Col md="3">
                                <div className="info info-hover">
                                    <div className="icon icon-white text-white">
                                        <i className="fa fa-coins" />
                                    </div>
                                    <h2 className="info-title text-sub text-white">Token Launch</h2>
                                    <p className="description text-hl  text-white">
                                        May 2021
                                    </p>
                                </div>
                            </Col>
                            <Col md="3">
                                <div className="info info-hover">
                                    <div className="icon icon-white text-white">
                                        <i className="fa fa-rocket" />
                                    </div>
                                    <h2 className="info-title text-sub text-white">Main Net Launch</h2>
                                    <p className="description text-hl  text-white">
                                        June 2021
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </section>

        )
    }
}